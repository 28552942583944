<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">Checkout N Go</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1"> Adventure starts here 🚀 </b-card-title>
          <b-card-text class="mb-2"> Make your app management easy and fun! </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm" #default="{ invalid }">
            <!-- 

USER ACCOUNT 

             -->
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <!-- First Name -->
              <b-form-group label="First Name" label-for="register-fname">
                <validation-provider #default="{ errors }" name="First Name" vid="fname" rules="required">
                  <b-form-input
                    id="register-fname"
                    v-model="model.first_name"
                    name="register-fname"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Joe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Last Name -->
              <b-form-group label="Last Name" label-for="register-lname">
                <validation-provider #default="{ errors }" name="Last Name" vid="lname" rules="required">
                  <b-form-input
                    id="register-lname"
                    v-model="model.last_name"
                    name="register-lname"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Bloogs"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Email" label-for="register-email">
                <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                  <b-form-input
                    id="register-email"
                    v-model="model.email"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="register-password" label="Password">
                <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="register-password"
                      v-model="model.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- password -->
              <b-form-group label-for="register-password" label="Repeat Password">
                <validation-provider #default="{ errors }" name="Repeat Password" vid="rpassword" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="register-rpassword"
                      v-model="model.password_repeat"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-rpassword"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- 

              SYSTEM

               -->

              <!-- Business Name -->
              <b-form-group label="Business Name" label-for="register-bname">
                <validation-provider #default="{ errors }" name="Business Name" vid="bname" rules="required">
                  <b-form-input
                    id="register-bname"
                    v-model="tenant.name"
                    name="register-bname"
                    :state="errors.length > 0 ? false : null"
                    placeholder="CheckoutNgo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Business Package -->
              <b-form-group label="Business Package" label-for="register-package">
                <validation-provider #default="{ errors }" name="Business Package" vid="package" rules="required">
                  <select id="register-package" class="form-control" required v-model="tenant.package" name="register-package">
                    <option value="1">Takeaway System</option>
                    <option value="2">Booking System (WIP)</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- 

PAYMENT!

 -->

              <!-- Stripe Card -->
              <b-form-group label="Card Number" label-for="register-cnumber">
                <validation-provider #default="{ errors }" name="Card Number" vid="cnumber" rules="required">
                  <b-form-input
                    id="register-cnumber"
                    v-model="card.number"
                    name="register-cnumber"
                    :state="errors.length > 0 ? false : null"
                    placeholder="1234 1234 1234 1234"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <span class="text-danger" v-show="cardNumberError">
                    {{ cardNumberError }}
                  </span>
                </validation-provider>
              </b-form-group>

              <b-row>
                <b-col lg="4">
                  <!-- Stripe Exp Month -->
                  <b-form-group label="Card Exp Month" label-for="register-cemonth">
                    <validation-provider #default="{ errors }" name="Card Exp Month" vid="cemonth" rules="required">
                      <b-form-input
                        id="register-cemonth"
                        v-model="card.exp_month"
                        name="register-cemonth"
                        :state="errors.length > 0 ? false : null"
                        placeholder="MM"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <span class="text-danger" v-show="cardMonthError">
                        {{ cardMonthError }}
                      </span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <!-- Stripe Exp Year -->
                  <b-form-group label="Card Exp Year" label-for="register-ceyear">
                    <validation-provider #default="{ errors }" name="Card Exp Year" vid="ceyear" rules="required">
                      <b-form-input
                        id="register-ceyear"
                        v-model="card.exp_year"
                        name="register-ceyear"
                        :state="errors.length > 0 ? false : null"
                        placeholder="YY"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <span class="text-danger" v-show="cardYearError">
                        {{ cardYearError }}
                      </span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <!-- Stripe CVC -->
                  <b-form-group label="Card CVC" label-for="register-ccvc">
                    <validation-provider #default="{ errors }" name="Card CVC" vid="ccvc" rules="required">
                      <b-form-input id="register-ccvc" v-model="card.cvc" name="register-ccvc" :state="errors.length > 0 ? false : null" placeholder="123" />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <span class="text-danger" v-show="cardCvcError">
                        {{ cardCvcError }}
                      </span>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Stripe Name -->
              <b-form-group label="Card Name" label-for="register-cname">
                <validation-provider #default="{ errors }" name="Card Name" vid="cname" rules="required">
                  <b-form-input
                    id="register-cname"
                    v-model="card.name"
                    name="register-cname"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Joe Bloogs"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <span class="text-danger" v-show="cardNameError">
                    {{ cardNameError }}
                  </span>
                </validation-provider>
              </b-form-group>

              <div class="text-danger" v-if="cardCheckError">
                <span>{{ cardCheckErrorMessage }}</span>
                <br />
              </div>
              <small class="text-danger" v-if="error">{{ error }}</small>
              <b-form-group>
                <b-form-checkbox id="register-privacy-policy" v-model="status" name="checkbox-1">
                  I agree to
                  <router-link to="/terms-conditions">privacy policy & terms</router-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button variant="primary" block type="submit" :disabled="invalid"> Sign up </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BFormSelect,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      tenant: {
        name: "",
        site_url: "",
        package: "1",
      },

      model: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_repeat: "",
      },
      stripe: "pk_test_51JtdbgAS7xvVPbYBD47IukU8m9jU9koUodnv5eHwOf6uv5RbTP1ApFDve6A3Us618CQ171CWjz4plJQvKrfBVW3u00VRgAfC07",
      card: {
        number: "",
        cvc: "",
        exp_month: "",
        exp_year: "",
        name: "",
      },
      // validation
      cardNumberError: null,
      cardCvcError: null,
      cardMonthError: null,
      cardYearError: null,
      cardNameError: null,
      cardCheckSending: false,
      cardCheckError: false,
      cardCheckErrorMessage: "",

      status: "",
      error: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    errorPrompt(msg) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Error`,
          icon: "CropIcon",
          variant: "danger",
          text: msg,
        },
      });
      this.$refs.registerForm.setErrors(msg);
      // Remove initial loading
      const appLoading = document.getElementById("loading-bg");
      if (appLoading) {
        appLoading.style.display = "none";
      }
    },
    register() {
      // Show initial loading
      const appLoading = document.getElementById("loading-bg");
      if (appLoading) {
        appLoading.style.display = "block";
      }
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          //user

          useJwt
            .register({
              email: this.model.email,
              first_name: this.model.first_name,
              last_name: this.model.last_name,
              password: this.model.password,
              tenant: 0,
              package: this.tenant.package,
            })
            .then((response) => {
              if (response.data.status == 200) {
                let user_id = response.data.data.id;
                useJwt
                  .register_create_tenant(user_id, {
                    user_id: user_id,
                    owner_id: user_id,
                    name: this.tenant.name,
                    slug: this.tenant.name
                      .toLowerCase()
                      .replace(/ /g, "-")
                      .replace(/[^\w-]+/g, ""),
                  })
                  .then((response) => {
                    if (response.data.status == 200) {
                      useJwt
                        .register_take_payment({
                          user_id: user_id,
                          tenant_id: response.data.data.id,
                          products: '[{"price": "price_1LzK66AS7xvVPbYBaSs99jMu"}]',
                          setup: '[{"price": "price_1KIxlbAS7xvVPbYBH9WGWkt6", "quantity": "1"}]',
                          card: this.card,
                        })
                        .then(() => {
                          this.$toast({
                            component: ToastificationContent,
                            position: "top-right",
                            props: {
                              title: `Welcome ${this.model.first_name} ${this.model.last_name}`,
                              icon: "CoffeeIcon",
                              variant: "success",
                              text: `You have successfully logged in as ${this.tenant.name}. Now you can start to explore!`,
                            },
                          });
                          const appLoading = document.getElementById("loading-bg");
                          if (appLoading) {
                            appLoading.style.display = "none";
                          }

                          this.$router.push("/login");
                        })
                        .catch((e) => {
                          console.log(e);
                          this.errorPrompt("Failed to take a payment");
                        });
                    } else {
                      this.errorPrompt(`We couldn't register business url / name.`);
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                    this.errorPrompt(`We couldn't register business url / name.`);
                  });
              } else if (response.data.message) {
                console.log(response);
                this.errorPrompt(response.data.message);
              } else {
                console.log(response);
                this.errorPrompt(`Unknow error occured`);
              }
            })
            .catch(() => {
              this.errorPrompt(`This e-mail address is being used by some other account.`);
            });
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
