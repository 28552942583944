var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v("Checkout N Go")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Adventure starts here 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Make your app management easy and fun! ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"register-fname"}},[_c('validation-provider',{attrs:{"name":"First Name","vid":"fname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-fname","name":"register-fname","state":errors.length > 0 ? false : null,"placeholder":"Joe"},model:{value:(_vm.model.first_name),callback:function ($$v) {_vm.$set(_vm.model, "first_name", $$v)},expression:"model.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"register-lname"}},[_c('validation-provider',{attrs:{"name":"Last Name","vid":"lname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-lname","name":"register-lname","state":errors.length > 0 ? false : null,"placeholder":"Bloogs"},model:{value:(_vm.model.last_name),callback:function ($$v) {_vm.$set(_vm.model, "last_name", $$v)},expression:"model.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"john@example.com"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Repeat Password"}},[_c('validation-provider',{attrs:{"name":"Repeat Password","vid":"rpassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-rpassword","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-rpassword","placeholder":"············"},model:{value:(_vm.model.password_repeat),callback:function ($$v) {_vm.$set(_vm.model, "password_repeat", $$v)},expression:"model.password_repeat"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Business Name","label-for":"register-bname"}},[_c('validation-provider',{attrs:{"name":"Business Name","vid":"bname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-bname","name":"register-bname","state":errors.length > 0 ? false : null,"placeholder":"CheckoutNgo"},model:{value:(_vm.tenant.name),callback:function ($$v) {_vm.$set(_vm.tenant, "name", $$v)},expression:"tenant.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Business Package","label-for":"register-package"}},[_c('validation-provider',{attrs:{"name":"Business Package","vid":"package","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tenant.package),expression:"tenant.package"}],staticClass:"form-control",attrs:{"id":"register-package","required":"","name":"register-package"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tenant, "package", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Takeaway System")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Booking System (WIP)")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Card Number","label-for":"register-cnumber"}},[_c('validation-provider',{attrs:{"name":"Card Number","vid":"cnumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-cnumber","name":"register-cnumber","state":errors.length > 0 ? false : null,"placeholder":"1234 1234 1234 1234"},model:{value:(_vm.card.number),callback:function ($$v) {_vm.$set(_vm.card, "number", $$v)},expression:"card.number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.cardNumberError),expression:"cardNumberError"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.cardNumberError)+" ")])]}}],null,true)})],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Card Exp Month","label-for":"register-cemonth"}},[_c('validation-provider',{attrs:{"name":"Card Exp Month","vid":"cemonth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-cemonth","name":"register-cemonth","state":errors.length > 0 ? false : null,"placeholder":"MM"},model:{value:(_vm.card.exp_month),callback:function ($$v) {_vm.$set(_vm.card, "exp_month", $$v)},expression:"card.exp_month"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.cardMonthError),expression:"cardMonthError"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.cardMonthError)+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Card Exp Year","label-for":"register-ceyear"}},[_c('validation-provider',{attrs:{"name":"Card Exp Year","vid":"ceyear","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-ceyear","name":"register-ceyear","state":errors.length > 0 ? false : null,"placeholder":"YY"},model:{value:(_vm.card.exp_year),callback:function ($$v) {_vm.$set(_vm.card, "exp_year", $$v)},expression:"card.exp_year"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.cardYearError),expression:"cardYearError"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.cardYearError)+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Card CVC","label-for":"register-ccvc"}},[_c('validation-provider',{attrs:{"name":"Card CVC","vid":"ccvc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-ccvc","name":"register-ccvc","state":errors.length > 0 ? false : null,"placeholder":"123"},model:{value:(_vm.card.cvc),callback:function ($$v) {_vm.$set(_vm.card, "cvc", $$v)},expression:"card.cvc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.cardCvcError),expression:"cardCvcError"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.cardCvcError)+" ")])]}}],null,true)})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Card Name","label-for":"register-cname"}},[_c('validation-provider',{attrs:{"name":"Card Name","vid":"cname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-cname","name":"register-cname","state":errors.length > 0 ? false : null,"placeholder":"Joe Bloogs"},model:{value:(_vm.card.name),callback:function ($$v) {_vm.$set(_vm.card, "name", $$v)},expression:"card.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.cardNameError),expression:"cardNameError"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.cardNameError)+" ")])]}}],null,true)})],1),(_vm.cardCheckError)?_c('div',{staticClass:"text-danger"},[_c('span',[_vm._v(_vm._s(_vm.cardCheckErrorMessage))]),_c('br')]):_vm._e(),(_vm.error)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" I agree to "),_c('router-link',{attrs:{"to":"/terms-conditions"}},[_vm._v("privacy policy & terms")])],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[_vm._v(" Sign up ")])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('span',[_vm._v(" Sign in instead")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }